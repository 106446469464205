* {
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
  margin: 0;
  color: rgba(50, 50, 50, 1);
  background: rgba(251, 251, 251, 1);
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Noto Sans JP", -apple-system, BlinkMacSystemFont,
    "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3",
    Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
  line-height: 2.1rem;
  letter-spacing: 0.05rem;
}

.root {
  min-height: 100vh;
  width: 100%;
}

@media screen and (max-width: 600px) {
  body {
    width: 650px;
  }
  .video-container {
    max-width: 650px !important;
  }
}
